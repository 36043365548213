import { createRouter, createWebHistory } from 'vue-router'
import store from "./../store";
import { RouteRecordRaw, RouteLocationNormalized } from 'vue-router';

// Auth
import Login from "./../views/auth/Login";
import ForgotPassword from "./../views/auth/ForgotPassword";

import Restricted from "./../views/dashboard/errors/Restricted";

// Dashboard
import Dashboard from "./../views/dashboard/Dashboard";

// Home
import Home from "./../views/dashboard/home/Home";

// Users
import UserRouter from "./../views/dashboard/users/UserRouter";
import UserIndex from "./../views/dashboard/users/Index";
import UserForm from "./../views/dashboard/users/Form";

// Roles
import RoleRouter from "./../views/dashboard/users/roles/RoleRouter";
import RoleIndex from "./../views/dashboard/users/roles/Index";
import RoleForm from "./../views/dashboard/users/roles/Form";

// Agenda
import Agenda from "./../views/dashboard/agenda/Agenda";

// Items
import ItemsRouter from "./../views/dashboard/items/ItemsRouter";
import ItemsIndex from "./../views/dashboard/items/Index";
import ItemsForm from "./../views/dashboard/items/Form";
import ItemsDetail from "./../views/dashboard/items/Detail";

// Items
import CompoundProductsRouter from "./../views/dashboard/compound_products/CompoundProductsRouter";
import CompoundProductsIndex from "./../views/dashboard/compound_products/Index";
import CompoundProductsForm from "./../views/dashboard/compound_products/Form";
import CompoundProductsDetail from "./../views/dashboard/compound_products/Detail";

// Inventory
import InventoryRouter from "./../views/dashboard/inventory/InventoryRouter";
import InventoryIndex from "./../views/dashboard/inventory/Index";
import InventoryForm from "./../views/dashboard/inventory/Form";

import InventoryConflictsRouter from "./../views/dashboard/inventory_conflicts/Router";
import InventoryConflictsIndex from "./../views/dashboard/inventory_conflicts/Index";

// Locations
import LocationRouter from "./../views/dashboard/inventory/locations/LocationsRouter";
import LocationIndex from "./../views/dashboard/inventory/locations/Index";
import LocationForm from "./../views/dashboard/inventory/locations/Form";

// Quotes
import QuotesRouter from "./../views/dashboard/quotes/QuotesRouter";
import QuotesIndex from "./../views/dashboard/quotes/Index";
import QuotesQuote from "./../views/dashboard/quotes/Quote";
import QuotesCreate from "./../views/dashboard/quotes/Create";

import { finances_routes } from './finances';
import { events_routes } from './events';
import { clients_routes } from './clients';
import { reports_routes } from './reports';

const routes = [
  {
    path: "/",
    redirect: { name: "login" }
  },
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: { requiresLogin: false },
  },
  {
    path: "/recuperar_password",
    component: ForgotPassword,
    name: "forgot_password",
    meta: { requiresLogin: false },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
    meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
    children: [
      {
        path: "/inicio",
        component: Home,
        name: "home",
        meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
      },
      {
        path: "/usuarios",
        component: UserRouter,
        name: "users",
        meta: { requiresLogin: true, roles: [], emails: [
          'marlene@relumbra.com',
          'erick@hagane.io',
        ]},
        redirect: { name: "user_index" },
        children: [
          {
            path: "indice",
            component: UserIndex,
            name: "user_index",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'erick@hagane.io',
            ]},
          },
          {
            path: "agregar",
            component: UserForm,
            name: "user_add",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'erick@hagane.io',
            ]},
          },
          {
            path: "editar/:id",
            component: UserForm,
            name: "user_edit",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'erick@hagane.io',
            ]},
          },
          {
            path: "roles",
            component: RoleRouter,
            name: "roles",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'erick@hagane.io',
            ]},
            redirect: { name: "role_index" },
            children: [
              {
                path: "indice",
                component: RoleIndex,
                name: "role_index",
                meta: { requiresLogin: true, roles: [], emails: [
                  'marlene@relumbra.com',
                  'erick@hagane.io',
                ]},
              },
              {
                path: "agregar",
                component: RoleForm,
                name: "role_add",
                meta: { requiresLogin: true, roles: [], emails: [
                  'marlene@relumbra.com',
                  'erick@hagane.io',
                ]},
              },
              {
                path: "editar/:id",
                component: RoleForm,
                name: "role_edit",
                meta: { requiresLogin: true, roles: [], emails: [
                  'marlene@relumbra.com',
                  'erick@hagane.io',
                ]},
              },
            ]
          }
        ]
      },
      finances_routes,
      clients_routes,
      reports_routes,
      {
        path: "/agenda",
        component: Agenda,
        name: "agenda",
        meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] }
      },
      {
        path: "/items",
        component: ItemsRouter,
        name: "items",
        meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
        redirect: { name: "items_index" },
        children: [
          {
            path: "indice",
            component: ItemsIndex,
            name: "items_index",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
          },
          {
            path: "agregar",
            component: ItemsForm,
            name: "items_add",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'inventario@relumbra.com',
              'erick@hagane.io',
            ] },
          },
          {
            path: "editar/:id",
            component: ItemsForm,
            name: "items_edit",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'inventario@relumbra.com',
              'erick@hagane.io',
              'info@relumbra.com',
            ] },
          },
          {
            path: "detalle",
            component: ItemsDetail,
            name: "items_detail",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
          },
        ]
      },
      {
        path: "/inventario",
        component: InventoryRouter,
        name: "inventory",
        meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
        redirect: { name: "inventory_index" },
        children: [
          {
            path: "indice",
            component: InventoryIndex,
            name: "inventory_index",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
          },
          {
            path: "agregar",
            component: InventoryForm,
            name: "inventory_add",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'inventario@relumbra.com',
              'erick@hagane.io',
            ] },
          },
          {
            path: "editar/:id",
            component: InventoryForm,
            name: "inventory_edit",
            meta: { requiresLogin: true, roles: [], emails: [
              'marlene@relumbra.com',
              'inventario@relumbra.com',
              'erick@hagane.io',
            ] },
          },
          {
            path: "ubicaciones",
            component: LocationRouter,
            name: "locations",
            meta: { requiresLogin: true, roles: ['admin'] },
            redirect: { name: "locations_index" },
            children: [
              {
                path: "indice",
                component: LocationIndex,
                name: "locations_index",
                meta: { requiresLogin: true, roles: ['admin'] },
              },
              {
                path: "agregar",
                component: LocationForm,
                name: "locations_add",
                meta: { requiresLogin: true, roles: ['admin'] },
              },
              {
                path: "editar/:id",
                component: LocationForm,
                name: "locations_edit",
                meta: { requiresLogin: true, roles: ['admin'] },
              },
            ]
          }
        ]
      },
      {
        path: "/conflictos-inventario",
        component: InventoryConflictsRouter,
        name: "inventory_conflicts",
        meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
        redirect: { name: "inventory_conflicts_index" },
        children: [
          {
            path: "indice",
            component: InventoryConflictsIndex,
            name: "inventory_conflicts_index",
            meta: { requiresLogin: true, roles: ['admin', 'logistics', 'seller', 'procurement', 'installer'] },
          },
        ]
      },
      {
        path: "/productos",
        component: CompoundProductsRouter,
        name: "compound_products",
        meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
        redirect: { name: "compound_products_index" },
        children: [
          {
            path: "indice",
            component: CompoundProductsIndex,
            name: "compound_products_index",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
          },
          {
            path: "agregar",
            component: CompoundProductsForm,
            name: "compound_products_add",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
          },
          {
            path: "editar/:id",
            component: CompoundProductsForm,
            name: "compound_products_edit",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
          },
          {
            path: "detalle",
            component: CompoundProductsDetail,
            name: "compound_products_detail",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
          },
        ]
      },
      events_routes,
      {
        path: "/cotizaciones",
        component: QuotesRouter,
        name: "quotes",
        meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] },
        redirect: { name: "quotes_index" },
        children: [
          {
            path: "indice",
            component: QuotesIndex,
            name: "quotes_index",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] }
          },
          {
            path: "cotizacion/:id?",
            component: QuotesQuote,
            name: "quotes_quote",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] }
          },
          {
            path: "agregar",
            component: QuotesCreate,
            name: "events_add",
            meta: { requiresLogin: true, roles: ['admin', 'seller', 'procurement'] }
          },
        ]
      },
      // errors
      {
        path: "/unauthorized",
        component: Restricted,
        name: "unauthorized",
        meta: { requiresLogin: true },
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresLogin) {
    if (from.name == 'login' && to.name == 'login') {
      return;
    }
    if (!store.state.user.role || store.state.user.role === '') {
      next({ name: 'login' });
    } else if (!to.meta.roles.includes(store.state.user.role)) {
      // super special permissions for users
      if (to.meta.emails && to.meta.emails.includes(store.state.user.email)) {
        next();
      }
      next({ name: 'unauthorized' });
    } 
    // else if (!store.state.permission.permissions.includes(to.meta.permission)) {
    //   next({ name: 'dashboard' });
    // } 
    else {
      next();
    }
  } else {
    next();
  }
});

export function getRolesByRouteName(name, routes) {
  const route = routes.find(route => route.name === 'dashboard').children.find(route => route.name === name);
  return route ? route.meta.roles : [];
}

export function routeNameHasRole(routeName, role) {
  const found_route = routes.find(route => route.name === 'dashboard').children.find(route => route.name === routeName);
  let roles = found_route ? found_route.meta.roles : [];
  return roles.includes(role);
}

export function routeNameHasEmail(routeName, role) {
  const found_route = routes.find(route => route.name === 'dashboard').children.find(route => route.name === routeName);
  let emails = found_route ? found_route.meta.emails : [];
  return emails.includes(role);
}

export default router

import ClientApi from "./clientApi";
import EventRequests from './projects';
import ReportRequests from './reports';

export default {
  ...EventRequests,
  ReportRequests,
  // Authentication

  login(form) {
    return ClientApi().post("/login", form);
  },

  // Users

  getUsers(query_params) {
    return ClientApi().get("/users", {params: query_params})
  },

  getUser(id) {
    return ClientApi().get("/users/"+id)
  },

  storeUser(form) {
    return ClientApi().post("/users", form);
  },

  updateUser(id, form) {
    return ClientApi().put("/users/" + id, form);
  },

  deleteUser(id) {
    return ClientApi().delete("/users/" + id);
  },

  // Roles

  getRoles() {
    return ClientApi().get("/roles")
  },

  storeRole(form) {
    return ClientApi().post("/roles", form);
  },

  updateRole(id, form) {
    return ClientApi().put("/roles/" + id, form);
  },

  // Clients

  getClients(params) {
    return ClientApi().get("/customers", {params: params});
  },

  viewClient(id) {
    return ClientApi().get("/customers/"+id);
  },

  editClient(id) {
    return ClientApi().post("/customers/"+id);
  },

  storeClient(form) {
    return ClientApi().post("/customers", form);
  },

  updateClient(id, form) {
    return ClientApi().put("/customers/" + id, form);
  },

  deleteClient(id, form) {
    return ClientApi().delete("/customers/" + id);
  },

  // BankingAccounts

  getAccounts() {
    return ClientApi().get("/banking_accounts");
  },

  // Departments

  getDepartments() {
    return ClientApi().get("/departments");
  },

  checkProductAvailabilityForEvent(params) {
    return ClientApi().post("/leasing_products/compound_products/availability", params );
  },
  // Branches

  getBranches() {
    return ClientApi().get("/branches");
  },

  // Products

  getProduct(id) {
    return ClientApi().get("/products/"+id);
  },

  getProducts() {
    return ClientApi().get("/products")
  },

  storeProduct(form) {
    return ClientApi().post("/products", form);
  },

  updateProduct(id, form) {
    return ClientApi().put("/products/"+id, form);
  },

  deleteProduct(id) {
    return ClientApi().delete("/products/"+id);
  },

  // Compound Products

  getCompoundProducts() {
    return ClientApi().get("/compound_products")
  },

  getCompoundProduct(id) {
    return ClientApi().get("/compound_products/"+id);
  },

  storeCompoundProduct(form) {
    return ClientApi().post("/compound_products", form);
  },

  updateCompoundProduct(id, form) {
    return ClientApi().put("/compound_products/"+id, form);
  },

  deleteCompoundProduct(id) {
    return ClientApi().delete("/compound_products/"+id);
  },

  // bank accounts

  getBankAccounts(params) {
    return ClientApi().get("/bank_accounts", {
      params: params
    });
  },

  getBankAccountTransfers() {
    return ClientApi().get("/bank_account_transfers");
  },

  getBankAccountTransfer(id) {
    return ClientApi().get("/bank_account_transfers/"+id);
  },

  storeBankAccountTransfer(form) {
    return ClientApi().post("/bank_account_transfers", form);
  },

  updateBankAccountTransfer(id, form) {
    return ClientApi().put("/bank_account_transfers/"+id, form);
  },

  deleteBankAccountTransfer(id) {
    return ClientApi().delete("/bank_account_transfers/"+id);
  },

  // income

  storePayment(form) {
    return ClientApi().post("/payments", form);
  },

  updatePayment(id, form) {
    return ClientApi().put("/payments/" + id, form);
  },

  getIncomes(params) {
    return ClientApi().get("/payment_incomes", {
      params: params
    });
  },

  getPayment(id) {
    return ClientApi().get("/payments/"+id);
  },

  deletePayment(id) {
    return ClientApi().delete("/payments/" + id);
  },

  getOutcomes(params) {
    return ClientApi().get("/payment_outcomes", {
      params: params
    });
  },

  uploadPaymentFile(payment_id, file) {
    let formData = new FormData();

    formData.append('payment_id', payment_id);
    formData.append('file', file);

    return ClientApi().post('https://api.relumbra.com/api/payment_files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  deletePaymentFile(id) {
    return ClientApi().delete('https://api.relumbra.com/api/payment_files/delete', {
      params: {
        file_id: id
      }
    });
  },

  uploadEventFile(event_id, file) {
    let formData = new FormData();

    formData.append('event_id', event_id);
    formData.append('file', file);

    return ClientApi().post('https://api.relumbra.com/api/event_files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  deleteEventFile(id) {
    return ClientApi().delete('https://api.relumbra.com/api/event_files/delete', {
      params: {
        file_id: id
      }
    });
  },

  // categories

  getPaymentCategories() {
    return ClientApi().get("/payment_categories");
  },

  getPaymentCategorie(id) {
    return ClientApi().get("/payment_categories/" + id);
  },

  storePaymentCategories(form) {
    return ClientApi().post("/payment_categories", form);
  },

  updatePaymentCategories(id, form) {
    return ClientApi().put("/payment_categories/" + id, form);
  },

  // subcategories (new)

  getPaymentSubcategories() {
    return ClientApi().get("/payment_subcategories");
  },

  getPaymentSubcategorie(id) {
    return ClientApi().get("/payment_subcategories/" + id);
  },

  storePaymentSubcategories(form) {
    return ClientApi().post("/payment_subcategories", form);
  },

  updatePaymentSubcategories(id, form) {
    return ClientApi().put("/payment_subcategories/" + id, form);
  },

  deletePaymentSubcategories(id, form) {
    return ClientApi().delete("/payment_subcategories/" + id);
  },

  // (new)

  getCategories(class_name) {
    return ClientApi().get("/categories?class=" + class_name);
  },

  getTaxonomies(class_name) {
    return ClientApi().get("/taxonomies?class=" + class_name);
  },

  // inventories

  getWarehouses(class_name) {
    return ClientApi().get("/warehouses");
  },

  getWarehouseProducts(class_name) {
    return ClientApi().get("/warehouse_products");
  },

  getWarehouseProduc(id) {
    return ClientApi().get("/warehouse_products/"+id);
  },

  storeWarehouseProduct(form) {
    return ClientApi().post("/warehouse_products", form);
  },

  updateWarehouseProduct(id, form) {
    return ClientApi().put("/warehouse_products/"+id, form);
  },

  // reports
  getBankAccountBalances(params) {
    return ClientApi().get("/bank_account_balances", {params: params});
  },

  getFinancesCuts(params) {
    return ClientApi().get("/finances_cut", {params: params});
  },

  getInventoryConflicts() {
    return ClientApi().get("/inventory_conflicts");
  },

  getInventoryConflictsSummary() {
    return ClientApi().get("/inventory_conflicts/summary");
  },

  updateInventoryConflict(id, form) {
    return ClientApi().put("/inventory_conflicts/"+id, form);
  },

  expiredPaymentsCount() {
    return ClientApi().get("/finances/expired_payments/count");
  },
};